#dashboard-navbar {
  .logo-icon {
    width: 30px;
    overflow: hidden;
  }
  .logo-text {
    background: linear-gradient(#99a9b9 0.5%, #426271);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2rem;
    font-weight: 700;
    margin-left: 5px;
    // letter-spacing: 0.05rem;
  }
  .user-avatar {
    width: 33px;
    overflow: hidden;
    opacity: 0.8;
  }
  .nav-link {
    cursor: pointer;
    font-size: 1.2rem;
    padding: 5px 15px;
  }
  .nav-link {
    color: rgba(0, 0, 0, 0.308);
  }
}

@media all and (max-width: 575px) {
  #dashboard-navbar {
    .logo-icon {
      width: 33px;
      margin-left: 10px;
    }
    .logo-text {
      display: none;
    }
    .username {
      display: none;
    }
    .user-avatar {
      margin: 0 7px;
    }
  }
}
