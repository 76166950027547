/* 
 * prefix p- stands for private to distiguish bootstrap classes from private ones 
 * */
@import "../../../styles/utils.scss";

#info {
  .p-title {
    color: $p-text_primary;
    font-size: 72px;
    background: linear-gradient(#99a9b9 0.5%, #426271);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -0.05rem;
  }
  .p-logo {
    margin-bottom: -1rem;
  }
}

@media all and (min-width: 992px) {
  #info {
    .p-wrapper {
      background: linear-gradient(175deg, #ffffff00 55%, #efecff);
      border-left: 8px solid #e6cc17 !important;
      border-radius: 6px;
      border-bottom-right-radius: 0px;
      box-shadow: -12px -7px 15px -16px rgba(0, 0, 0, 0.37),
        -22px -20px 28px -29px rgba(0, 0, 0, 0.205);
      border-bottom: none !important;
      border-top: none !important;
    }
  }
}
