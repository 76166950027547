#criteriaForm {
  label {
    color: gray !important;
  }
  .btn-primary {
    background: rgb(98, 160, 98);
    border: none;
    border-radius: 0;
  }
  input,
  .select__control {
    border-color: rgb(228, 228, 228);
    border-radius: 2px;
  }
  .range-value {
    display: inline-block;
    font-size: 1.4rem;
    color: rgb(125, 156, 125);
    font-weight: 600;
  }
  .select__multi-value__label,
  .select__multi-value__remove {
    color: white;
    background: rgb(148, 126, 148);
    border-radius: 0;
  }
  .select__multi-value__remove {
    &:hover {
      cursor: pointer;
    }
  }
  .slider {
    appearance: none;
    height: 10px;
    outline: none !important;
    background-color: rgb(244, 226, 245);
    background-image: linear-gradient(
      to right,
      rgb(250, 233, 255),
      rgb(255, 230, 220)
    );
    border: none;
    border-radius: 30px;
    transition: 0.2s linear;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: rgb(229, 222, 233);
    }
    &::-webkit-slider-thumb {
      appearance: none;
      width: 10px;
      height: 25px;
      background: gray;
      border-radius: 3px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
