#chooseType {
  .card {
    cursor: pointer;
  }
  .card-body {
    padding-top: 30px;
    padding-bottom: 30px;
    font-weight: 200;
    font-size: 1.8rem;
  }
  .chooseType-heading {
    font-size: 2rem;
    font-weight: 300;
    color: rgb(163, 160, 165) !important;
  }
  #type-Influencer {
    background-image: linear-gradient(
      70deg,
      rgb(241, 135, 135),
      rgb(226, 78, 78)
    );
  }
  #type-Brand {
    background-image: linear-gradient(
      20deg,
      rgb(186, 85, 233),
      rgb(226, 146, 231)
    );
  }
}
