#influencers {
  .image-container {
    transition: 0.2s linear;
    img {
      max-width: 100% !important;
    }
    &:hover {
      transform: scale(1.05);
    }
  }

  h3 {
    font-size: 3.5rem !important;
    font-weight: 700;
    background: linear-gradient(#7d8ea0 1%, #4e7181);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -0.05rem;
    margin-bottom: 0;
  }

  .slick-arrow {
    &.slick-next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -10px;
      z-index: 50;

      &::before {
        // content: "\f054";
        // font-family: "Font Awesome 5 Free";
        // position: absolute;
        // right: 10px;
        color: rgb(117, 117, 117) !important;
        display: inline-block;
        font-size: 35px;
        font-weight: 900;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
      }
    }
    &.slick-prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -25px;
      z-index: 50;

      &::before {
        // content: "\f053";
        // font-family: "Font Awesome 5 Free";
        // position: absolute;
        // left: 10px;
        color: rgb(117, 117, 117) !important;
        display: inline-block;
        font-size: 35px;
        font-weight: 900;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
      }
    }
  }
}

@media all and (max-width: 991px) {
  #influencers {
    h3 {
      font-size: 3rem !important;
    }
    .image-container {
      width: 80% !important;
    }
    .slick-arrow {
      &.slick-next {
        width: 35px;
        height: 40px;
        right: -12px !important;
      }
      &.slick-prev {
        width: 35px;
        height: 40px;
        left: -15px;
      }
    }
  }
}
