.alert-success {
  transform: scale(0.9);
  animation: pop 0.4s linear forwards;
}

@keyframes pop {
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
