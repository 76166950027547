@import url(https://fonts.googleapis.com/css?family=Kalam|Titillium+Web:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Kalam|Titillium+Web:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Kalam|Titillium+Web:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Kalam|Titillium+Web:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Kalam|Titillium+Web:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  overflow-x: hidden;
  text-align: center;
}

.navbar .logo-title {
  font-size: 2.5rem !important;
  color: #5b5b5b;
  font-weight: 400 !important; }

.navbar .navbar-logo-container {
  max-width: 110px; }

.navbar .logo-subtitle {
  color: #6d6d6d;
  font-weight: 200;
  margin-top: -2px !important; }

.navbar .active > .nav-link {
  color: #aa3b92 !important; }

@media all and (max-width: 768px) {
  .navbar .navbar-logo-container {
    max-width: 100%; }
  .navbar .logo-title {
    font-size: 1.4rem !important; }
  .navbar .logo-subtitle {
    font-size: 0.8rem; }
  .navbar .navbar-toggler {
    background-color: #7e8da1 !important;
    border: none !important;
    border-radius: 0;
    color: white; } }


#banner {
  background-image: url(/imgs/banner.jpg);
  min-height: 500px;
  background-position: bottom center;
  background-color: #eae9e7;
  background-repeat: no-repeat;
  background-size: cover; }
  #banner .searchbar-wrapper {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important; }
  #banner h2 {
    font-weight: 800 !important;
    line-height: 2.9rem !important;
    font-size: 3.2rem;
    text-align: start;
    color: #546e7a; }
    #banner h2:nth-of-type(1) {
      color: #5f7985; }
    #banner h2:nth-of-type(2) {
      font-size: 2.75rem; }
    #banner h2:nth-of-type(3) {
      color: #475d68; }

@media all and (max-width: 576px) {
  #banner {
    background-position-x: -510px; } }

@media all and (max-width: 768px) {
  #banner h2 {
    font-size: 3rem !important;
    letter-spacing: normal;
    letter-spacing: initial;
    text-align: center; } }

@media all and (min-width: 1400px) {
  #banner {
    background-image: url(/imgs/banner-wide.jpg); } }

@media all and (min-width: 1600px) {
  #banner {
    background-image: url(/imgs/banner-xwide.jpg); } }

.p-btn-gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e8db2e+0,dd2a75+50,db2397+51,84bcf4+100 */
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #e8db2e 0%, #dd2a75 45%, #db2397 55%, #84bcf4 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#e8db2e), color-stop(45%, #dd2a75), color-stop(55%, #db2397), to(#84bcf4));
  background: linear-gradient(to right, #e8db2e 0%, #dd2a75 45%, #db2397 55%, #84bcf4 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8db2e', endColorstr='#84bcf4',GradientType=1 );
  /* IE6-9 */ }

.p-btn-gradient {
  border: none;
  cursor: pointer;
  color: white;
  font-weight: 700; }

#offer {
  max-height: 2500px;
  overflow: hidden;
  -webkit-transition: 0.25s linear;
  transition: 0.25s linear;
  color: #eeeeee !important; }
  #offer .getoffer-wrapper {
    background: -webkit-linear-gradient(280deg, #de3d70 -5%, #405698 62%);
    background: linear-gradient(170deg, #de3d70 -5%, #405698 62%);
    box-shadow: 12px 7px 15px -16px rgba(0, 0, 0, 0.37), 22px 20px 40px -29px rgba(0, 0, 0, 0.705) !important; }
  #offer .offer-display-title {
    font-size: 1.5rem;
    font-weight: 200 !important;
    border-bottom: 1px solid #ffffff24; }
  #offer .offer-display-number {
    font-size: 2.1rem;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: "Titillium Web", sans-serif !important;
    text-shadow: 0 0 40px rgba(0, 0, 0, 0.233);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    padding: 6px 30px; }
  #offer .estimate-wrapper {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: 0 -0.7rem 0.6rem rgba(0, 0, 0, 0.05) !important;
    border-radius: 4px;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(30%, #377d0f), color-stop(80%, #377d0f33), to(#60519100));
    background: -webkit-linear-gradient(#377d0f 30%, #377d0f33 80%, #60519100);
    background: linear-gradient(#377d0f 30%, #377d0f33 80%, #60519100); }
  #offer .estimate-title {
    font-size: 1.8rem;
    font-weight: 600;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 20px;
    border-radius: 4px;
    color: #eeb014; }
  #offer .estimate-number {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 2.8rem !important;
    margin-top: -5px;
    font-weight: 600;
    font-family: "Titillium Web", sans-serif !important; }
  #offer .code {
    background-color: #a1a1a1;
    padding: 1px 6px;
    border-radius: 3px;
    color: white; }
  #offer .topic {
    background-color: #415484;
    font-size: 1.2rem;
    font-weight: 400;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    opacity: 0;
    -webkit-animation: topicAnimation 0.5s linear forwards;
            animation: topicAnimation 0.5s linear forwards; }

@-webkit-keyframes topicAnimation {
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes topicAnimation {
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }
  #offer .alert-warning {
    -webkit-animation: shake 0.2s linear forwards;
            animation: shake 0.2s linear forwards; }

@-webkit-keyframes shake {
  20% {
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px); }
  50% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  75% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes shake {
  20% {
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px); }
  50% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  75% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@-webkit-keyframes expand {
  100% {
    max-height: 1500px; } }

@keyframes expand {
  100% {
    max-height: 1500px; } }

.getoffer-form {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden; }
  .getoffer-form input#textInput {
    flex-grow: 1;
    border: none;
    padding: 10px;
    outline: none; }
  .getoffer-form select#iconSelect {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: auto; }
  .getoffer-form input[type="submit"] {
    padding: 0.9rem 1.2rem !important;
    margin-top: -0.3rem;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0px;
    border-top-right-radius: 4px;
    height: 55px;
    cursor: pointer;
    font-weight: 700;
    border: none;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e8db2e+0,dd2a75+50,db2397+51,84bcf4+100 */
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #e8db2e 0%, #dd2a75 45%, #db2397 60%, #84bcf4 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, from(#e8db2e), color-stop(45%, #dd2a75), color-stop(60%, #db2397), to(#84bcf4));
    background: linear-gradient(to right, #e8db2e 0%, #dd2a75 45%, #db2397 60%, #84bcf4 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8db2e', endColorstr='#84bcf4',GradientType=1 );
    /* IE6-9 */ }

@media all and (max-width: 768px) {
  #get-offer-form h2 {
    font-size: 3rem !important;
    letter-spacing: normal;
    letter-spacing: initial;
    text-align: center; }
  #get-offer-form.getoffer-form {
    margin: 0 auto;
    margin-top: -5px !important; }
    #get-offer-form.getoffer-form input[type="text"] {
      width: 300px; }
    #get-offer-form.getoffer-form input[type="submit"] {
      width: 300px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      padding: 0.7rem !important;
      height: auto; }
  #get-offer-form .linearProgress {
    width: 300px; } }

#potential-offers .offer-logo {
  width: 80px; }

#potential-offers .offers-title {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-color: rgba(255, 255, 255, 0.164) !important; }
  #potential-offers .offers-title::after {
    content: "";
    display: inline-block;
    width: 150px;
    height: 1px;
    /* color: white; */
    background-color: rgba(255, 255, 255, 0.151);
    position: absolute;
    top: 50%;
    right: -170px; }
  #potential-offers .offers-title::before {
    content: "";
    display: inline-block;
    width: 150px;
    height: 1px;
    /* color: white; */
    background-color: rgba(255, 255, 255, 0.151);
    position: absolute;
    top: 50%;
    left: -170px; }

#potential-offers .offers {
  position: relative; }
  #potential-offers .offers::after {
    content: "";
    display: inline-block;
    width: 60%;
    height: 1px;
    /* color: white; */
    background-color: rgba(255, 255, 255, 0.151);
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }

#potential-offers .getCustomOffer-header {
  color: rgba(255, 255, 255, 0.993) !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 300;
  background-color: #377c0f; }

#potential-offers .getCustomOffer-subHeader {
  font-weight: 200;
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.668);
  max-width: 500px;
  line-height: 1.2rem; }

#potential-offers .brands-fading {
  color: linear-gradient(rgba(255, 255, 255, 0), white); }

#potential-offers .pot-brand {
  opacity: 0.07; }

#potential-offers .unlock-offers {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -55%);
          transform: translate(-50%, -55%);
  opacity: 0.75; }
  #potential-offers .unlock-offers p {
    font-weight: 300;
    font-size: 1.2rem; }

@media all and (max-width: 772px) {
  #potential-offers .offers-title::after {
    content: none; }
  #potential-offers .offers-title::before {
    content: none; } }

.p-btn-gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e8db2e+0,dd2a75+50,db2397+51,84bcf4+100 */
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #e8db2e 0%, #dd2a75 45%, #db2397 55%, #84bcf4 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#e8db2e), color-stop(45%, #dd2a75), color-stop(55%, #db2397), to(#84bcf4));
  background: linear-gradient(to right, #e8db2e 0%, #dd2a75 45%, #db2397 55%, #84bcf4 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8db2e', endColorstr='#84bcf4',GradientType=1 );
  /* IE6-9 */ }

.p-btn-gradient {
  border: none;
  cursor: pointer;
  color: white;
  font-weight: 700; }

#custom-offer {
  position: relative;
  max-height: 0px;
  -webkit-animation: expand 0.5s linear forwards;
          animation: expand 0.5s linear forwards;
  overflow: hidden; }
  #custom-offer .custom-offer-form {
    background-color: #3c4349; }
  #custom-offer .linear-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }

@-webkit-keyframes expand {
  100% {
    max-height: 1000px; } }

@keyframes expand {
  100% {
    max-height: 1000px; } }

.alert-danger {
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
  -webkit-animation: shake 0.2s linear forwards;
          animation: shake 0.2s linear forwards; }

@-webkit-keyframes shake {
  20% {
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px); }
  50% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  75% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes shake {
  20% {
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px); }
  50% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  75% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

#dash-account .account-property {
  align-items: center; }
  @media all and (max-width: 450px) {
    #dash-account .account-property {
      flex-direction: column;
      align-items: flex-start; } }

#dash-account .account-name {
  font-size: 1.8rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.671); }

#dash-account .account-key {
  display: inline-block;
  min-width: 140px;
  padding-bottom: 3px;
  font-size: 1.3rem;
  font-weight: 300; }

#dash-account .account-value {
  display: inline-block;
  padding: 1px 40px;
  background: #bdbdbd;
  border-radius: 0px;
  color: white;
  font-weight: 400;
  font-size: 1rem;
  margin-left: 15px;
  text-shadow: 1px 0 10px rgba(0, 0, 0, 0.158);
  border-left: 3px solid rgba(214, 49, 255, 0.493); }
  @media all and (max-width: 450px) {
    #dash-account .account-value {
      margin-left: 0;
      padding: 1px 30px;
      font-size: 0.95rem; } }

.section-title {
  color: #b74fbb;
  font-size: 1.5rem;
  font-weight: 400;
  font-family: ubuntu, Helvetica; }

/* 
 * prefix p- stands for private to distiguish bootstrap classes from private ones 
 * */
.p-btn-gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e8db2e+0,dd2a75+50,db2397+51,84bcf4+100 */
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #e8db2e 0%, #dd2a75 45%, #db2397 55%, #84bcf4 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#e8db2e), color-stop(45%, #dd2a75), color-stop(55%, #db2397), to(#84bcf4));
  background: linear-gradient(to right, #e8db2e 0%, #dd2a75 45%, #db2397 55%, #84bcf4 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8db2e', endColorstr='#84bcf4',GradientType=1 );
  /* IE6-9 */ }

.p-btn-gradient {
  border: none;
  cursor: pointer;
  color: white;
  font-weight: 700; }

#info .p-title {
  color: #546e7a;
  font-size: 72px;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.5%, #99a9b9), to(#426271));
  background: -webkit-linear-gradient(#99a9b9 0.5%, #426271);
  background: linear-gradient(#99a9b9 0.5%, #426271);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.05rem; }

#info .p-logo {
  margin-bottom: -1rem; }

@media all and (min-width: 992px) {
  #info .p-wrapper {
    background: -webkit-linear-gradient(275deg, #ffffff00 55%, #efecff);
    background: linear-gradient(175deg, #ffffff00 55%, #efecff);
    border-left: 8px solid #e6cc17 !important;
    border-radius: 6px;
    border-bottom-right-radius: 0px;
    box-shadow: -12px -7px 15px -16px rgba(0, 0, 0, 0.37), -22px -20px 28px -29px rgba(0, 0, 0, 0.205);
    border-bottom: none !important;
    border-top: none !important; } }

#sample .ytp-icon path {
  fill: #212121 !important; }

#influencers .image-container {
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear; }
  #influencers .image-container img {
    max-width: 100% !important; }
  #influencers .image-container:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }

#influencers h3 {
  font-size: 3.5rem !important;
  font-weight: 700;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(1%, #7d8ea0), to(#4e7181));
  background: -webkit-linear-gradient(#7d8ea0 1%, #4e7181);
  background: linear-gradient(#7d8ea0 1%, #4e7181);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.05rem;
  margin-bottom: 0; }

#influencers .slick-arrow.slick-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: -10px;
  z-index: 50; }
  #influencers .slick-arrow.slick-next::before {
    color: #757575 !important;
    display: inline-block;
    font-size: 35px;
    font-weight: 900;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased; }

#influencers .slick-arrow.slick-prev {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: -25px;
  z-index: 50; }
  #influencers .slick-arrow.slick-prev::before {
    color: #757575 !important;
    display: inline-block;
    font-size: 35px;
    font-weight: 900;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased; }

@media all and (max-width: 991px) {
  #influencers h3 {
    font-size: 3rem !important; }
  #influencers .image-container {
    width: 80% !important; }
  #influencers .slick-arrow.slick-next {
    width: 35px;
    height: 40px;
    right: -12px !important; }
  #influencers .slick-arrow.slick-prev {
    width: 35px;
    height: 40px;
    left: -15px; } }

#opportunities h3 {
  font-size: 3.5rem !important;
  font-weight: 700;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(1%, #7d8ea0), to(#4e7181));
  background: -webkit-linear-gradient(#7d8ea0 1%, #4e7181);
  background: linear-gradient(#7d8ea0 1%, #4e7181);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.05rem; }

#opportunities .card {
  position: relative;
  -webkit-transition: 0.3s linear;
  transition: 0.3s linear; }
  #opportunities .card .p-card-img {
    width: 100%;
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
    -webkit-transition: 0.4s linear;
    transition: 0.4s linear; }
  #opportunities .card:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    box-shadow: -6px -7px 6px -2px rgba(0, 0, 0, 0.082), -25px -26px 30px -29px rgba(0, 0, 0, 0.175), 7px 2px 20px -20px rgba(0, 0, 0, 0.322); }
    #opportunities .card:hover .p-card-img {
      -webkit-transform: scale(1);
              transform: scale(1); }
    #opportunities .card:hover .logo {
      -webkit-transform: scale(1.15);
              transform: scale(1.15); }

#opportunities .card-img-top {
  overflow: hidden;
  position: relative;
  max-height: 230px;
  -webkit-transition: 0.3s linear;
  transition: 0.3s linear;
  box-shadow: 5px -6px 6px -6px rgba(0, 0, 0, 0.199), 14px -16px 18px -16px rgba(0, 0, 0, 0.181); }
  #opportunities .card-img-top::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.452); }

#opportunities .logo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50; }
  #opportunities .logo-overlay .logo {
    max-width: 60%;
    -webkit-transition: 0.4s linear;
    transition: 0.4s linear; }

#opportunities .card-text {
  line-height: 1.8rem !important;
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px; }

@media all and (max-width: 576px) {
  #opportunities h3 {
    font-size: 3rem !important; } }

.p-btn-gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e8db2e+0,dd2a75+50,db2397+51,84bcf4+100 */
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #e8db2e 0%, #dd2a75 45%, #db2397 55%, #84bcf4 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#e8db2e), color-stop(45%, #dd2a75), color-stop(55%, #db2397), to(#84bcf4));
  background: linear-gradient(to right, #e8db2e 0%, #dd2a75 45%, #db2397 55%, #84bcf4 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8db2e', endColorstr='#84bcf4',GradientType=1 );
  /* IE6-9 */ }

.p-btn-gradient {
  border: none;
  cursor: pointer;
  color: white;
  font-weight: 700; }

#connect h3 {
  font-weight: 500;
  font-size: 3.5rem !important;
  color: #546e7a; }

#connect .jumbotron {
  border-right: 8px solid #9c95dc;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(1%, #f7f6fff7), to(#f3f3f321));
  background: -webkit-linear-gradient(#f7f6fff7 1%, #f3f3f321);
  background: linear-gradient(#f7f6fff7 1%, #f3f3f321);
  box-shadow: 12px 7px 15px -16px rgba(0, 0, 0, 0.37), 22px 20px 28px -29px rgba(0, 0, 0, 0.205);
  -webkit-transition: 0.25s linear;
  transition: 0.25s linear; }
  #connect .jumbotron:hover {
    box-shadow: 12px 7px 15px -12px rgba(0, 0, 0, 0.37), 22px 20px 35px -29px rgba(0, 0, 0, 0.205), 40px 40px 45px -29px rgba(0, 0, 0, 0.137); }

@media all and (max-width: 991px) {
  #connect h3 {
    font-size: 2.1rem !important;
    line-height: 2.4rem; } }

.p-btn-gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e8db2e+0,dd2a75+50,db2397+51,84bcf4+100 */
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #e8db2e 0%, #dd2a75 45%, #db2397 55%, #84bcf4 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#e8db2e), color-stop(45%, #dd2a75), color-stop(55%, #db2397), to(#84bcf4));
  background: linear-gradient(to right, #e8db2e 0%, #dd2a75 45%, #db2397 55%, #84bcf4 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8db2e', endColorstr='#84bcf4',GradientType=1 );
  /* IE6-9 */ }

.p-btn-gradient {
  border: none;
  cursor: pointer;
  color: white;
  font-weight: 700; }

#aboutus .page-title {
  color: #546e7a !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500; }

#aboutus .p-title {
  font-family: Arial, Helvetica, sans-serif !important;
  letter-spacing: 2px;
  color: #546e7a; }

#aboutus .row.aboutus-section {
  position: relative; }
  #aboutus .row.aboutus-section::before {
    content: "\201D";
    position: absolute;
    top: -20px;
    left: 0px;
    font-size: 300px;
    color: rgba(0, 0, 0, 0.03); }
  #aboutus .row.aboutus-section::after {
    content: "\201D";
    position: absolute;
    bottom: -150px;
    right: 50%;
    font-size: 300px;
    color: rgba(0, 0, 0, 0.03); }

#aboutus .why-ziigg {
  background-image: url(/imgs/cta1.jpg);
  position: relative;
  background-size: cover;
  background-position: center;
  z-index: -1;
  margin-top: 150px !important; }

#aboutus .why-ziigg-overlay {
  background-color: white;
  opacity: 0.93;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1; }

#aboutus .why-ziigg-title {
  color: #00acc1 !important;
  font-weight: 600;
  font-size: 3rem; }

#aboutus .why-ziigg-subtitle {
  color: rgba(0, 0, 0, 0.38) !important;
  font-weight: 200;
  letter-spacing: 0.2rem; }

#aboutus .why-ziigg-text {
  font-weight: 200 !important;
  font-size: 1.3rem !important; }

@media all and (max-width: 760px) {
  .row.aboutus-section {
    background-color: #efeeea !important; } }

#login .login-form {
  max-width: 420px; }

#login .p-form-label {
  display: none !important; }

#login .form-control {
  border-radius: 0;
  border: none;
  background-color: #f7f5f8;
  height: 50px; }

#login .form-control-error {
  background-color: #f09696; }

#login .btn-primary {
  font-weight: 600;
  padding-top: 13px;
  padding-bottom: 13px;
  border: 0;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(-5%, #f05989), to(#5a9edd));
  background-image: -webkit-linear-gradient(left, #f05989 -5%, #5a9edd);
  background-image: linear-gradient(to right, #f05989 -5%, #5a9edd); }

#signup .signup-form {
  max-width: 550px; }

#signup .p-form-label {
  display: none !important; }

#signup .form-control {
  border-radius: 0;
  border: none;
  background-color: #f7f5f8;
  height: 50px; }

#signup .form-control-error {
  background-color: #f09696; }

#signup .form-control-success {
  background-color: #8ad87a;
  background-image: -webkit-gradient(linear, left top, right top, from(#afe9af), to(#8ed88e));
  background-image: -webkit-linear-gradient(left, #afe9af, #8ed88e);
  background-image: linear-gradient(to right, #afe9af, #8ed88e); }

#signup .btn-primary {
  font-weight: 600;
  padding-top: 13px;
  padding-bottom: 13px;
  border: 0;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(-5%, #f05989), to(#5a9edd));
  background-image: -webkit-linear-gradient(left, #f05989 -5%, #5a9edd);
  background-image: linear-gradient(to right, #f05989 -5%, #5a9edd); }

#recursiveQuestions .animate-expand {
  max-height: 0;
  overflow: hidden;
  -webkit-animation: expandQuestion 1.2s linear forwards;
          animation: expandQuestion 1.2s linear forwards; }

#recursiveQuestions .child-question {
  border-left: 1px solid #ebebeb;
  padding-left: 7px; }
  #recursiveQuestions .child-question:first-child {
    padding-top: 20px; }
  #recursiveQuestions .child-question:last-child {
    padding-bottom: 20px; }

@-webkit-keyframes expandQuestion {
  100% {
    max-height: 1000px; } }

@keyframes expandQuestion {
  100% {
    max-height: 1000px; } }

.alert-success {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-animation: pop 0.4s linear forwards;
          animation: pop 0.4s linear forwards; }

@-webkit-keyframes pop {
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes pop {
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

#formField {
  position: relative; }
  #formField .field-validation {
    position: absolute;
    top: 14px;
    right: 10px; }
  #formField textarea {
    min-height: 80px; }

#chooseType .card {
  cursor: pointer; }

#chooseType .card-body {
  padding-top: 30px;
  padding-bottom: 30px;
  font-weight: 200;
  font-size: 1.8rem; }

#chooseType .chooseType-heading {
  font-size: 2rem;
  font-weight: 300;
  color: #a3a0a5 !important; }

#chooseType #type-Influencer {
  background-image: -webkit-linear-gradient(20deg, #f18787, #e24e4e);
  background-image: linear-gradient(70deg, #f18787, #e24e4e); }

#chooseType #type-Brand {
  background-image: -webkit-linear-gradient(70deg, #ba55e9, #e292e7);
  background-image: linear-gradient(20deg, #ba55e9, #e292e7); }


#dashCard {
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  background-color: #ce53ce;
  font-family: "Ubuntu", sans-serif; }
  #dashCard .dashcard-title {
    font-family: inherit;
    font-size: 1.2rem; }
  #dashCard .dashcard-body {
    font-size: 1.6rem;
    font-weight: 500; }
  #dashCard .dashcard-meta {
    font-family: inherit;
    font-weight: 500; }
  #dashCard.dashcard-pink {
    background-image: -webkit-linear-gradient(120deg, #e94075, #e2a58d);
    background-image: linear-gradient(-30deg, #e94075, #e2a58d); }
  #dashCard.dashcard-purple {
    background-image: -webkit-linear-gradient(120deg, #c446c4, #e07ae0);
    background-image: linear-gradient(-30deg, #c446c4, #e07ae0); }
  #dashCard.dashcard-pink-blue {
    background-image: -webkit-linear-gradient(125deg, #6e94ff, #eb7c9f 95%);
    background-image: linear-gradient(-35deg, #6e94ff, #eb7c9f 95%); }
  #dashCard.dashcard-green-blue {
    background-image: -webkit-linear-gradient(125deg, #70acec, #52d6c0 99%);
    background-image: linear-gradient(-35deg, #70acec, #52d6c0 99%); }
  #dashCard::after {
    content: "";
    position: absolute;
    height: 105%;
    width: 40%;
    right: -15%;
    top: -15%;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1); }
  #dashCard::before {
    content: "";
    position: absolute;
    height: 60%;
    width: 20%;
    right: 5%;
    bottom: -20%;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1); }

.ReactTable {
  border: none !important;
  background-color: white; }
  .ReactTable .rt-table {
    border-radius: 5px;
    overflow: hidden; }
  .ReactTable .rt-thead {
    background-color: #9583c6;
    color: white;
    min-height: 62px;
    margin-bottom: 15px;
    font-weight: 600;
    box-shadow: none !important; }
    .ReactTable .rt-thead .rt-th {
      display: flex;
      justify-content: center;
      cursor: pointer; }
  .ReactTable .rt-tbody .rt-tr-group {
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: ubuntu, Helvetica;
    font-weight: 500;
    color: #5b636e;
    border: none !important; }
    .ReactTable .rt-tbody .rt-tr-group:nth-of-type(even) {
      background-color: #f7f7f8; }
  .ReactTable .rt-tbody .rt-td:first-of-type {
    font-weight: 300;
    font-size: 1.2rem; }
    @media all and (max-width: 450px) {
      .ReactTable .rt-tbody .rt-td:first-of-type {
        font-size: 1rem; } }
  .ReactTable .rt-tbody .reserve-btn {
    cursor: pointer;
    border-radius: 3px;
    padding: 2px 10px;
    border: none; }
  .ReactTable .rt-resizable-header-content {
    align-self: center; }
  .ReactTable .pagination-bottom {
    margin-top: 15px;
    background-color: #fcfcfd; }
    .ReactTable .pagination-bottom .-pagination {
      height: 60px;
      border-top: 1px solid #f8f8f8;
      box-shadow: none !important; }
      .ReactTable .pagination-bottom .-pagination .-btn {
        background-color: #dddddd; }

#dash-reports .lineChart-container {
  border-radius: 6px; }


#dash-leads .list-group-item {
  border: none;
  margin-bottom: 1px;
  background-color: #f9fbfb;
  background-image: -webkit-linear-gradient(340deg, #fcfcfc 50%, rgba(254, 255, 255, 0.548));
  background-image: linear-gradient(110deg, #fcfcfc 50%, rgba(254, 255, 255, 0.548));
  margin-bottom: 8px;
  border-left: 7px solid #b3babd;
  -webkit-transition: 0.3s linear;
  transition: 0.3s linear; }
  #dash-leads .list-group-item:hover {
    -webkit-transform: scale(1.006);
            transform: scale(1.006);
    border-left: 7px solid #9d80aa;
    box-shadow: 2px 2px 6px -3px rgba(0, 0, 0, 0.21);
    background-image: -webkit-linear-gradient(340deg, #fafafa 50%, #f9fbfc);
    background-image: linear-gradient(110deg, #fafafa 50%, #f9fbfc); }
  #dash-leads .list-group-item.new-lead-today {
    border-left: 7px solid #9cb9c7;
    -webkit-transition: 0.3s linear;
    transition: 0.3s linear; }
  #dash-leads .list-group-item.new-lead {
    background-image: -webkit-linear-gradient(340deg, #fff8f5 50%, #f9fbfc);
    background-image: linear-gradient(110deg, #fff8f5 50%, #f9fbfc);
    border-left: 7px solid #d89f79;
    -webkit-transition: 0.3s linear;
    transition: 0.3s linear; }

#dash-leads .list-item-key {
  display: flex;
  align-items: center;
  flex: 1 1;
  flex-basis: 280px; }
  @media all and (max-width: 450px) {
    #dash-leads .list-item-key {
      min-width: 250px;
      justify-content: space-between; } }

#dash-leads .list-item-value {
  display: inline-block;
  max-width: 220px;
  font-size: 1.4rem;
  font-weight: 200;
  color: #6088b9;
  word-wrap: break-word;
  margin-left: 10px; }
  #dash-leads .list-item-value.text-sm {
    font-size: 1.25rem;
    font-weight: 400; }
  @media all and (max-width: 450px) {
    #dash-leads .list-item-value {
      max-width: 180px; } }
  @media all and (max-width: 1150px) {
    #dash-leads .list-item-value {
      font-size: 1.2rem; } }

#dash-leads .dropdown {
  cursor: pointer; }

#dash-leads .btn {
  min-width: 150px;
  color: #8f8f8f;
  background-color: #f9fbfb !important; }

#dash-leads .delete-icon {
  display: flex !important;
  opacity: 1;
  color: dimgray; }
  #dash-leads .delete-icon .MuiSvgIcon-root:hover {
    color: #d83838;
    cursor: pointer; }

@media all and (max-width: 450px) {
  #dash-leads .delete-icon {
    display: none !important;
    opacity: 0; }
  #dash-leads .delete-icon.clicked {
    display: flex !important;
    opacity: 1;
    color: dimgray; }
    #dash-leads .delete-icon.clicked .MuiSvgIcon-root:hover {
      color: #d83838;
      cursor: pointer; } }

#dash-leads .time-from-container {
  cursor: pointer;
  border-radius: 10px; }
  #dash-leads .time-from-container:hover .time-from {
    color: #756767;
    box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.07); }

#dash-leads .time-from {
  box-shadow: 1px 1px 5px -3px rgba(0, 0, 0, 0);
  color: rgba(180, 180, 180, 0.637);
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear; }

#dash-team .team-add-btn {
  padding: 1rem 2.5rem;
  border: none;
  background-color: #56b87b;
  color: white;
  font-size: 1.3rem;
  font-weight: 300; }
  #dash-team .team-add-btn:hover {
    text-decoration: none; }

#dash-team .team-add-wrapper {
  max-height: 1000px;
  overflow: hidden;
  -webkit-transition: 0.5s linear;
  transition: 0.5s linear; }
  #dash-team .team-add-wrapper.hide {
    max-height: 0px;
    overflow: hidden;
    -webkit-transition: 0.5s linear;
    transition: 0.5s linear; }

.dropDownTab {
  width: inherit;
  -webkit-transition: 0.3s linear;
  transition: 0.3s linear; }
  .dropDownTab:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01); }
  .dropDownTab .dropDownTab-main {
    height: auto;
    width: inherit;
    background-color: white;
    cursor: pointer; }
  .dropDownTab .dropDownTab-title {
    font-size: 1.8rem; }
    @media all and (max-width: 450px) {
      .dropDownTab .dropDownTab-title {
        font-size: 1.2rem; } }
  .dropDownTab .dropDownTab-dropDown {
    overflow: hidden;
    -webkit-transition: 0.2s linear;
    transition: 0.2s linear; }

#teamlist {
  word-break: break-all; }
  #teamlist .teamlist-list {
    list-style: none;
    padding-left: 0; }
  #teamlist .teamlist-item {
    background: #fbfafb; }
  #teamlist .teamlist-item-title {
    font-size: 1.4rem;
    margin-bottom: 15px;
    line-height: 1; }
    @media all and (max-width: 450px) {
      #teamlist .teamlist-item-title {
        font-size: 1.4rem;
        margin-bottom: 18px; } }
  #teamlist .teamlist-item-metric {
    margin-right: 45px;
    padding-left: 5px;
    line-height: 1.1;
    margin-bottom: 7px;
    flex: 1 1;
    flex-basis: 250px; }
    @media all and (max-width: 450px) {
      #teamlist .teamlist-item-metric {
        margin-bottom: 12px; } }
    #teamlist .teamlist-item-metric.pending {
      border-left-color: rgba(228, 191, 122, 0.842); }
    #teamlist .teamlist-item-metric.finished {
      border-left-color: rgba(223, 223, 223, 0.89); }
    #teamlist .teamlist-item-metric.region {
      border-left-color: rgba(107, 199, 122, 0.76); }
  #teamlist .item-value {
    font-weight: 500;
    color: #677ebd; }

#dash-brands .list-group-item {
  cursor: pointer;
  border: none;
  margin-bottom: 1px;
  background-color: #f9fbfb;
  background-image: -webkit-linear-gradient(340deg, #fcfcfc 50%, rgba(254, 255, 255, 0.548));
  background-image: linear-gradient(110deg, #fcfcfc 50%, rgba(254, 255, 255, 0.548));
  margin-bottom: 8px;
  border-left: 7px solid #b3babd;
  -webkit-transition: 0.3s linear;
  transition: 0.3s linear; }
  #dash-brands .list-group-item:hover {
    -webkit-transform: scale(1.006);
            transform: scale(1.006);
    border-left: 7px solid #9d80aa;
    box-shadow: 2px 2px 6px -3px rgba(0, 0, 0, 0.21);
    background-image: -webkit-linear-gradient(340deg, #fafafa 50%, #f9fbfc);
    background-image: linear-gradient(110deg, #fafafa 50%, #f9fbfc); }
  #dash-brands .list-group-item.new-lead-today {
    border-left: 7px solid #9cb9c7;
    -webkit-transition: 0.3s linear;
    transition: 0.3s linear; }
  #dash-brands .list-group-item.new-lead {
    background-image: -webkit-linear-gradient(340deg, #fff8f5 50%, #f9fbfc);
    background-image: linear-gradient(110deg, #fff8f5 50%, #f9fbfc);
    border-left: 7px solid #d89f79;
    -webkit-transition: 0.3s linear;
    transition: 0.3s linear; }

#dash-brands .list-item-key {
  display: flex;
  align-items: center;
  flex: 1 1;
  flex-basis: 280px;
  color: #616161; }
  @media all and (max-width: 450px) {
    #dash-brands .list-item-key {
      min-width: 250px;
      justify-content: space-between; } }

#dash-brands .list-item-value {
  display: inline-block;
  max-width: 300px;
  font-size: 1.4rem;
  font-weight: 200;
  color: #6088b9;
  word-wrap: break-word;
  margin-left: 10px; }
  #dash-brands .list-item-value.text-sm {
    font-size: 1.25rem;
    font-weight: 400; }
  @media all and (max-width: 450px) {
    #dash-brands .list-item-value {
      max-width: 180px; } }
  @media all and (max-width: 1150px) {
    #dash-brands .list-item-value {
      font-size: 1.2rem; } }

#dash-brands .sub-li {
  flex-basis: 100%;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: 0.25s linear;
  transition: 0.25s linear; }
  #dash-brands .sub-li.expand {
    max-height: 1200px; }
  #dash-brands .sub-li ul {
    margin-top: 16px;
    padding-left: 10px; }
  #dash-brands .sub-li .sub-li-item {
    flex-basis: 32%;
    padding-bottom: 0.6rem;
    color: gray; }
  #dash-brands .sub-li .list-item-value {
    line-height: 1.1;
    font-size: 1.1rem; }
  #dash-brands .sub-li .sub-li__title {
    font-size: 1.6rem;
    flex-basis: 100%;
    color: #a7a7a7;
    font-weight: 200;
    padding-left: 18px;
    border-left: 5px solid #b98dcf;
    line-height: 1.4;
    border-radius: 5px;
    box-shadow: -3px 1px 13px -6px rgba(0, 0, 0, 0.113);
    margin-bottom: 25px; }

#dash-brands .dropdown {
  cursor: pointer; }

#dash-brands .btn {
  min-width: 150px;
  color: #8f8f8f;
  background-color: #f9fbfb !important; }

#dash-brands .delete-icon {
  display: flex !important;
  opacity: 1;
  color: dimgray; }
  #dash-brands .delete-icon .MuiSvgIcon-root:hover {
    color: #d83838;
    cursor: pointer; }

@media all and (max-width: 450px) {
  #dash-brands .delete-icon {
    display: none !important;
    opacity: 0; }
  #dash-brands .delete-icon.clicked {
    display: flex !important;
    opacity: 1;
    color: dimgray; }
    #dash-brands .delete-icon.clicked .MuiSvgIcon-root:hover {
      color: #d83838;
      cursor: pointer; } }

#dash-brands .time-from-container {
  cursor: pointer;
  border-radius: 10px; }
  #dash-brands .time-from-container:hover .time-from {
    color: #756767;
    box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.07); }

#dash-brands .time-from {
  box-shadow: 1px 1px 5px -3px rgba(0, 0, 0, 0);
  color: rgba(180, 180, 180, 0.637);
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear; }

#dash-redirects .redirect-add-btn {
  padding: 1rem 2.5rem;
  border: none;
  background-color: #56b87b;
  color: white;
  font-size: 1.3rem;
  font-weight: 300; }
  #dash-redirects .redirect-add-btn:hover {
    text-decoration: none; }

#dash-redirects .redirect-add-wrapper {
  max-height: 1000px;
  overflow: hidden;
  -webkit-transition: 0.5s linear;
  transition: 0.5s linear; }
  #dash-redirects .redirect-add-wrapper.hide {
    max-height: 0px;
    overflow: hidden;
    -webkit-transition: 0.5s linear;
    transition: 0.5s linear; }


#criteriaForm label {
  color: gray !important; }

#criteriaForm .btn-primary {
  background: #62a062;
  border: none;
  border-radius: 0; }

#criteriaForm input,
#criteriaForm .select__control {
  border-color: #e4e4e4;
  border-radius: 2px; }

#criteriaForm .range-value {
  display: inline-block;
  font-size: 1.4rem;
  color: #7d9c7d;
  font-weight: 600; }

#criteriaForm .select__multi-value__label,
#criteriaForm .select__multi-value__remove {
  color: white;
  background: #947e94;
  border-radius: 0; }

#criteriaForm .select__multi-value__remove:hover {
  cursor: pointer; }

#criteriaForm .slider {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  height: 10px;
  outline: none !important;
  background-color: #f4e2f5;
  background-image: -webkit-gradient(linear, left top, right top, from(#fae9ff), to(#ffe6dc));
  background-image: -webkit-linear-gradient(left, #fae9ff, #ffe6dc);
  background-image: linear-gradient(to right, #fae9ff, #ffe6dc);
  border: none;
  border-radius: 30px;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear; }
  #criteriaForm .slider:focus {
    outline: none; }
  #criteriaForm .slider:hover {
    background-color: #e5dee9; }
  #criteriaForm .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
            appearance: none;
    width: 10px;
    height: 25px;
    background: gray;
    border-radius: 3px; }
    #criteriaForm .slider::-webkit-slider-thumb:hover {
      cursor: pointer; }

#dash-criteriaDisplay .account-property {
  align-items: center; }
  @media all and (max-width: 450px) {
    #dash-criteriaDisplay .account-property {
      flex-direction: column;
      align-items: flex-start; } }

#dash-criteriaDisplay .account-name {
  font-size: 1.8rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.671); }

#dash-criteriaDisplay .account-key {
  display: inline-block;
  min-width: 250px;
  padding-bottom: 3px;
  font-size: 1.3rem;
  font-weight: 300;
  color: #a5a5a5; }

#dash-criteriaDisplay .account-value {
  display: inline-block;
  padding: 1px 60px;
  min-width: 250px;
  text-align: center;
  background: #9b9a9f;
  border-radius: 0px;
  color: white;
  font-weight: 400;
  font-size: 1rem;
  text-shadow: 1px 0 10px rgba(0, 0, 0, 0.158);
  border-left: 5px solid rgba(214, 49, 255, 0.493);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 5px; }
  @media all and (max-width: 450px) {
    #dash-criteriaDisplay .account-value {
      margin-left: 0;
      padding: 1px 30px;
      font-size: 0.95rem; } }

#dashboard-navbar .logo-icon {
  width: 30px;
  overflow: hidden; }

#dashboard-navbar .logo-text {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.5%, #99a9b9), to(#426271));
  background: -webkit-linear-gradient(#99a9b9 0.5%, #426271);
  background: linear-gradient(#99a9b9 0.5%, #426271);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2rem;
  font-weight: 700;
  margin-left: 5px; }

#dashboard-navbar .user-avatar {
  width: 33px;
  overflow: hidden;
  opacity: 0.8; }

#dashboard-navbar .nav-link {
  cursor: pointer;
  font-size: 1.2rem;
  padding: 5px 15px; }

#dashboard-navbar .nav-link {
  color: rgba(0, 0, 0, 0.308); }

@media all and (max-width: 575px) {
  #dashboard-navbar .logo-icon {
    width: 33px;
    margin-left: 10px; }
  #dashboard-navbar .logo-text {
    display: none; }
  #dashboard-navbar .username {
    display: none; }
  #dashboard-navbar .user-avatar {
    margin: 0 7px; } }

#dash-left-navbar a {
  color: #5a6879;
  font-size: 1rem; }
  #dash-left-navbar a.active {
    color: purple; }
    #dash-left-navbar a.active + i {
      color: rgba(128, 0, 128, 0.651); }

#dash-left-navbar .nav-item {
  cursor: pointer;
  position: relative; }

#dash-left-navbar .fas,
#dash-left-navbar .far {
  position: absolute;
  right: 20px;
  top: 25%;
  color: #adb3b6; }

@media (max-width: 991.98px) {
  #dash-left-navbar a {
    display: none; }
  #dash-left-navbar .fas,
  #dash-left-navbar .far {
    position: relative;
    margin: 0 auto;
    top: auto;
    right: auto;
    margin-bottom: 15px; }
  #dash-left-navbar .nav-item {
    text-align: center; } }

#dash-left-navbar a {
  color: #5a6879;
  font-size: 1rem; }
  #dash-left-navbar a.active {
    color: purple; }
    #dash-left-navbar a.active + i {
      color: rgba(128, 0, 128, 0.651); }

#dash-left-navbar .nav-item {
  cursor: pointer;
  position: relative; }

#dash-left-navbar .fas,
#dash-left-navbar .far {
  position: absolute;
  right: 20px;
  top: 25%;
  color: #adb3b6; }

@media (max-width: 991.98px) {
  #dash-left-navbar a {
    display: none; }
  #dash-left-navbar .fas,
  #dash-left-navbar .far {
    position: relative;
    margin: 0 auto;
    top: auto;
    right: auto;
    margin-bottom: 15px; }
  #dash-left-navbar .nav-item {
    text-align: center; } }

#dash-left-navbar a {
  color: #5a6879;
  font-size: 1rem; }
  #dash-left-navbar a.active {
    color: purple; }
    #dash-left-navbar a.active + i {
      color: rgba(128, 0, 128, 0.651); }

#dash-left-navbar .nav-item {
  cursor: pointer;
  position: relative; }

#dash-left-navbar .fas,
#dash-left-navbar .far {
  position: absolute;
  right: 20px;
  top: 25%;
  color: #adb3b6; }

@media (max-width: 991.98px) {
  #dash-left-navbar a {
    display: none; }
  #dash-left-navbar .fas,
  #dash-left-navbar .far {
    position: relative;
    margin: 0 auto;
    top: auto;
    right: auto;
    margin-bottom: 15px; }
  #dash-left-navbar .nav-item {
    text-align: center; } }

/* 
*
* This css file holds common css among
* all dashboards. vendor specific css
* are kept in their respective folders
*
*/
#dashboard {
  text-align: start; }
  #dashboard .row {
    min-height: inherit; }
  #dashboard .dash-leftNav {
    min-height: inherit;
    width: 260px; }
  #dashboard .dash-main {
    min-height: inherit;
    flex-grow: 1;
    background-color: #f2edf3; }

@media (max-width: 1150px) {
  #dashboard .dash-leftNav {
    width: 210px; } }

@media (max-width: 991.98px) {
  #dashboard .dash-leftNav {
    width: 100px; }
  #dashboard .dash-leftNav-container {
    min-width: 100px !important;
    -webkit-transition: 0.3s linear;
    transition: 0.3s linear;
    overflow: hidden;
    flex-basis: 0; }
    #dashboard .dash-leftNav-container.hide {
      min-width: 0px !important;
      -webkit-transition: 0.3s linear;
      transition: 0.3s linear; } }

#contactus .contactus-info-container {
  background-color: #a27c98; }

#reset .reset-form {
  max-width: 550px; }

#reset .p-form-label {
  display: none !important; }

#reset .form-control {
  margin-top: 1rem;
  border-radius: 0;
  border: none;
  background-color: #f7f5f8;
  height: 50px; }

#reset .form-control-error {
  background-color: #f09696; }

#reset .form-control-success {
  background-color: #8ad87a;
  background-image: -webkit-gradient(linear, left top, right top, from(#afe9af), to(#8ed88e));
  background-image: -webkit-linear-gradient(left, #afe9af, #8ed88e);
  background-image: linear-gradient(to right, #afe9af, #8ed88e); }

#reset .btn-primary {
  font-weight: 600;
  padding-top: 13px;
  padding-bottom: 13px;
  border: 0;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(-5%, #f05989), to(#5a9edd));
  background-image: -webkit-linear-gradient(left, #f05989 -5%, #5a9edd);
  background-image: linear-gradient(to right, #f05989 -5%, #5a9edd); }

#resetrequest .reset-form {
  max-width: 550px; }

#resetrequest .p-form-label {
  display: none !important; }

#resetrequest .form-control {
  border-radius: 0;
  border: none;
  background-color: #f7f5f8;
  height: 50px; }

#resetrequest .form-control-error {
  background-color: #f09696; }

#resetrequest .form-control-success {
  background-color: #8ad87a;
  background-image: -webkit-gradient(linear, left top, right top, from(#afe9af), to(#8ed88e));
  background-image: -webkit-linear-gradient(left, #afe9af, #8ed88e);
  background-image: linear-gradient(to right, #afe9af, #8ed88e); }

#resetrequest .btn-primary {
  font-weight: 600;
  padding-top: 13px;
  padding-bottom: 13px;
  border: 0;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(-5%, #f05989), to(#5a9edd));
  background-image: -webkit-linear-gradient(left, #f05989 -5%, #5a9edd);
  background-image: linear-gradient(to right, #f05989 -5%, #5a9edd); }

