#formField {
  position: relative;
  .field-validation {
    position: absolute;
    top: 14px;
    right: 10px;
  }

  textarea {
    min-height: 80px;
  }
}
