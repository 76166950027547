#dash-account {
  .account-property {
    align-items: center;
    @media all and (max-width: 450px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .account-name {
    font-size: 1.8rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.671);
  }
  .account-key {
    display: inline-block;
    min-width: 140px;
    padding-bottom: 3px;
    font-size: 1.3rem;
    font-weight: 300;
  }
  .account-value {
    display: inline-block;
    padding: 1px 40px;
    background: rgb(189, 189, 189);
    border-radius: 0px;
    color: white;
    font-weight: 400;
    font-size: 1rem;
    margin-left: 15px;
    text-shadow: 1px 0 10px rgba(0, 0, 0, 0.158);
    border-left: 3px solid rgba(214, 49, 255, 0.493);

    @media all and (max-width: 450px) {
      margin-left: 0;
      padding: 1px 30px;
      font-size: 0.95rem;
    }
  }
}
