.ReactTable {
  border: none !important;
  background-color: rgb(255, 255, 255);
  .rt-table {
    border-radius: 5px;
    overflow: hidden;
  }
  .rt-thead {
    background-color: #9583c6;
    color: white;
    min-height: 62px;
    margin-bottom: 15px;
    font-weight: 600;
    box-shadow: none !important;
    .rt-th {
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
  }
  .rt-tbody {
    .rt-tr-group {
      padding-top: 5px;
      padding-bottom: 5px;
      font-family: ubuntu, Helvetica;
      font-weight: 500;
      color: rgb(91, 99, 110);
      border: none !important;
      &:nth-of-type(even) {
        background-color: rgb(247, 247, 248);
      }
    }
    .rt-td:first-of-type {
      font-weight: 300;
      font-size: 1.2rem;

      @media all and (max-width: 450px) {
        font-size: 1rem;
      }
    }
    .reserve-btn {
      cursor: pointer;
      border-radius: 3px;
      padding: 2px 10px;
      border: none;
    }
  }
  .rt-resizable-header-content {
    align-self: center;
  }
  .pagination-bottom {
    margin-top: 15px;
    background-color: rgb(252, 252, 253);
    .-pagination {
      height: 60px;
      border-top: 1px solid rgb(248, 248, 248);
      box-shadow: none !important;
      .-btn {
        background-color: rgb(221, 221, 221);
      }
    }
  }
}
