#reset {
    .reset-form {
      max-width: 550px;
    }
    .p-form-label {
      display: none !important;
    }
    .form-control {
    margin-top: 1rem;
      border-radius: 0;
      border: none;
      background-color: rgb(247, 245, 248);
      height: 50px;
    }
    .form-control-error {
      background-color: rgb(240, 150, 150);
    }
    .form-control-success {
      background-color: rgb(138, 216, 122);
      background-image: linear-gradient(
        to right,
        rgb(175, 233, 175),
        rgb(142, 216, 142)
      );
    }
    .btn-primary {
      font-weight: 600;
      padding-top: 13px;
      padding-bottom: 13px;
      border: 0;
      background-image: linear-gradient(to right, #f05989 -5%, rgb(90, 158, 221));
    }
  }
  