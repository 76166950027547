/* 
*
* This css file holds common css among
* all dashboards. vendor specific css
* are kept in their respective folders
*
*/

#dashboard {
  text-align: start;
  .row {
    min-height: inherit;
  }
  .dash-leftNav {
    min-height: inherit;
    width: 260px;
  }
  .dash-main {
    min-height: inherit;
    flex-grow: 1;
    background-color: #f2edf3;
  }
}

// for smaller laptop screens
@media (max-width: 1150px) {
  #dashboard {
    .dash-leftNav {
      width: 210px;
    }
  }
}

@media (max-width: 991.98px) {
  #dashboard {
    .dash-leftNav {
      width: 100px;
    }
    .dash-leftNav-container {
      min-width: 100px !important;
      transition: 0.3s linear;
      overflow: hidden;

      // require to hide element when using flex-grow
      flex-basis: 0;

      &.hide {
        min-width: 0px !important;
        transition: 0.3s linear;
      }
    }
  }
}
