#dash-left-navbar {
  a {
    color: #5a6879;
    font-size: 1rem;

    &.active {
      color: purple;

      & + i {
        color: rgba(128, 0, 128, 0.651);
      }
    }
  }
  .nav-item {
    cursor: pointer;
    position: relative;
  }
  .fas,
  .far {
    position: absolute;
    right: 20px;
    top: 25%;
    color: rgb(173, 179, 182);
  }
}

@media (max-width: 991.98px) {
  #dash-left-navbar {
    a {
      display: none;
    }
    .fas,
    .far {
      position: relative;
      margin: 0 auto;
      top: auto;
      right: auto;
      margin-bottom: 15px;
    }
    .nav-item {
      text-align: center;
    }
  }
}
