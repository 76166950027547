@import "../../../styles/utils.scss";

#custom-offer {
  position: relative;
  max-height: 0px;
  animation: expand 0.5s linear forwards;
  //   transition: 0.3s linear;
  overflow: hidden;
  .custom-offer-form {
    background-color: #3c4349;
  }
  .linear-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@keyframes expand {
  100% {
    max-height: 1000px;
  }
}
