.getoffer-form {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  input#textInput {
    flex-grow: 1;
    border: none;
    padding: 10px;
    outline: none;
}

select#iconSelect {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    // background: url('path_to_default_icon.png') no-repeat right;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: auto;
}

  input[type="submit"] {
    padding: 0.9rem 1.2rem !important;
    margin-top: -0.3rem;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0px;
    border-top-right-radius: 4px;
    height: 55px;
    cursor: pointer;
    font-weight: 700;
    border: none;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e8db2e+0,dd2a75+50,db2397+51,84bcf4+100 */
    background: -moz-linear-gradient(
      left,
      #e8db2e 0%,
      #dd2a75 45%,
      #db2397 60%,
      #84bcf4 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      #e8db2e 0%,
      #dd2a75 45%,
      #db2397 60%,
      #84bcf4 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      #e8db2e 0%,
      #dd2a75 45%,
      #db2397 60%,
      #84bcf4 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8db2e', endColorstr='#84bcf4',GradientType=1 ); /* IE6-9 */
  }
}

@media all and (max-width: 768px) {
  #get-offer-form {
    h2 {
      font-size: 3rem !important;
      letter-spacing: initial;
      text-align: center;
    }
    &.getoffer-form {
      margin: 0 auto;
      margin-top: -5px !important;
      input[type="text"] {
        width: 300px;
      }
      input[type="submit"] {
        width: 300px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        padding: 0.7rem !important;
        height: auto;
      }
    }
    .linearProgress {
      width: 300px;
    }
  }
}
