#opportunities {
  h3 {
    font-size: 3.5rem !important;
    font-weight: 700;
    background: linear-gradient(#7d8ea0 1%, #4e7181);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -0.05rem;
  }

  .card {
    // width: 350px;
    position: relative;
    transition: 0.3s linear;
    .p-card-img {
      width: 100%;
      transform: scale(1.15);
      transition: 0.4s linear;
    }
    &:hover {
      transform: scale(1.02);
      box-shadow: -6px -7px 6px -2px rgba(0, 0, 0, 0.082),
        -25px -26px 30px -29px rgba(0, 0, 0, 0.175),
        7px 2px 20px -20px rgba(0, 0, 0, 0.322);
      .p-card-img {
        transform: scale(1);
      }
      .logo {
        transform: scale(1.15);
      }
    }
  }
  .card-img-top {
    overflow: hidden;
    position: relative;
    max-height: 230px;
    transition: 0.3s linear;
    box-shadow: 5px -6px 6px -6px rgba(0, 0, 0, 0.199),
      14px -16px 18px -16px rgba(0, 0, 0, 0.181);
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.452);
    }
  }

  .logo-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;

    .logo {
      max-width: 60%;
      transition: 0.4s linear;
    }
  }
  .card-text {
    line-height: 1.8rem !important;
    font-weight: 300;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media all and (max-width: 576px) {
  #opportunities {
    h3 {
      font-size: 3rem !important;
    }
  }
}
