.navbar {
  .logo-title {
    font-size: 2.5rem !important;
    color: #5b5b5b;
    font-weight: 400 !important;
  }

  .navbar-logo-container {
    max-width: 110px;
  }
  .logo-subtitle {
    color: #6d6d6d;
    font-weight: 200;
    margin-top: -2px !important;
  }

  .active > .nav-link {
    color: #aa3b92 !important;
  }
}

@media all and (max-width: 768px) {
  .navbar {
    .navbar-logo-container {
      max-width: 100%;
    }
    .logo-title {
      font-size: 1.4rem !important;
    }
    .logo-subtitle {
      font-size: 0.8rem;
    }
    .navbar-toggler {
      background-color: rgb(126, 141, 161) !important;
      border: none !important;
      border-radius: 0;
      color: white;
    }
  }
}

// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { ... }

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) { ... }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) { ... }

// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }
