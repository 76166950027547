#teamlist {
  word-break: break-all;
  .teamlist-list {
    list-style: none;
    padding-left: 0;
  }
  .teamlist-item {
    background: #fbfafb;
  }
  .teamlist-item-title {
    font-size: 1.4rem;
    margin-bottom: 15px;
    line-height: 1;

    @media all and (max-width: 450px) {
      font-size: 1.4rem;
      margin-bottom: 18px;
    }
  }
  .teamlist-item-metric {
    // min-width: 250px;
    margin-right: 45px;
    // border-left: 4px solid rgba(114, 170, 117, 0.287);
    padding-left: 5px;
    line-height: 1.1;
    margin-bottom: 7px;
    flex: 1;
    flex-basis: 250px;

    @media all and (max-width: 450px) {
      margin-bottom: 12px;
    }

    &.pending {
      border-left-color: rgba(228, 191, 122, 0.842);
    }
    &.finished {
      border-left-color: rgba(223, 223, 223, 0.89);
    }
    &.region {
      border-left-color: rgba(107, 199, 122, 0.76);
    }
  }
  .item-value {
    font-weight: 500;
    color: rgb(103, 126, 189);
  }
}
