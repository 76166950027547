#potential-offers {
  .offer-logo {
    width: 80px;
  }
  .offers-title {
    position: relative;
    width: fit-content;
    border-color: rgba(255, 255, 255, 0.164) !important;

    &::after {
      content: "";
      display: inline-block;
      width: 150px;
      height: 1px;
      /* color: white; */
      background-color: rgba(255, 255, 255, 0.151);
      position: absolute;
      top: 50%;
      right: -170px;
    }
    &::before {
      content: "";
      display: inline-block;
      width: 150px;
      height: 1px;
      /* color: white; */
      background-color: rgba(255, 255, 255, 0.151);
      position: absolute;
      top: 50%;
      left: -170px;
    }
  }
  .offers {
    position: relative;
    &::after {
      content: "";
      display: inline-block;
      width: 60%;
      height: 1px;
      /* color: white; */
      background-color: rgba(255, 255, 255, 0.151);
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .getCustomOffer-header {
    color: rgba(255, 255, 255, 0.993) !important;
    width: fit-content;
    font-weight: 300;
    // border-color: rgb(138, 152, 158) !important;
    background-color: rgb(55, 124, 15);
  }
  .getCustomOffer-subHeader {
    font-weight: 200;
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.668);
    max-width: 500px;
    line-height: 1.2rem;
  }

  .brands-fading {
    color: linear-gradient(rgba(255, 255, 255, 0), white);
  }
  .pot-brand {
    opacity: 0.07;
  }
  .unlock-offers {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    opacity: 0.75;

    p {
      font-weight: 300;
      font-size: 1.2rem;
    }
  }
}

@media all and (max-width: 772px) {
  #potential-offers {
    .offer-logo {
    }
    .offers-title {
      &::after {
        content: none;
      }
      &::before {
        content: none;
      }
    }
  }
}
