#recursiveQuestions {
  .animate-expand {
    max-height: 0;
    overflow: hidden;
    animation: expandQuestion 1.2s linear forwards;
  }

  .child-question {
    border-left: 1px solid rgb(235, 235, 235);
    padding-left: 7px;
    &:first-child {
      padding-top: 20px;
    }
    &:last-child {
      padding-bottom: 20px;
    }
  }

  @keyframes expandQuestion {
    100% {
      max-height: 1000px;
    }
  }
}
