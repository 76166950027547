.alert-danger {
  transform: translateX(10px);
  animation: shake 0.2s linear forwards;
}

@keyframes shake {
  20% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
