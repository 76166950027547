@import "../../../styles/utils.scss";

#connect {
  h3 {
    font-weight: 500;
    font-size: 3.5rem !important;
    color: $p-text-primary;
  }
  .jumbotron {
    border-right: 8px solid #9c95dc;
    background: linear-gradient(#f7f6fff7 1%, #f3f3f321);
    // box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.04),
    //   0 6px 30px 5px rgba(0, 0, 0, 0.02), 0 8px 10px -7px rgba(0, 0, 0, 0.1);
    box-shadow: 12px 7px 15px -16px rgba(0, 0, 0, 0.37),
      22px 20px 28px -29px rgba(0, 0, 0, 0.205);
    transition: 0.25s linear;

    &:hover {
      //   box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.07),
      //     0 9px 36px 8px rgba(0, 0, 0, 0.05), 0 11px 15px -7px rgba(0, 0, 0, 0.1);
      box-shadow: 12px 7px 15px -12px rgba(0, 0, 0, 0.37),
        22px 20px 35px -29px rgba(0, 0, 0, 0.205),
        40px 40px 45px -29px rgba(0, 0, 0, 0.137);
    }
  }
}

@media all and (max-width: 991px) {
  #connect {
    h3 {
      font-size: 2.1rem !important;
      line-height: 2.4rem;
    }
  }
}

// #connect {
//   h3 {
//     font-weight: 500;
//     font-size: 3.5rem !important;
//     color: $p-text-primary;
//   }
//   .jumbotron {
//     border-left: 7px solid #e6cd16;
//     background: linear-gradient(175deg, #f3f3f321 1%, #f7f6fff7);
//     // box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.04),
//     //   0 6px 30px 5px rgba(0, 0, 0, 0.02), 0 8px 10px -7px rgba(0, 0, 0, 0.1);
//     box-shadow: -12px -7px 15px -16px rgba(0, 0, 0, 0.37),
//       -22px -20px 28px -29px rgba(0, 0, 0, 0.205);
//     transition: 0.25s linear;

//     &:hover {
//       box-shadow: -12px -7px 15px -12px rgba(0, 0, 0, 0.37),
//         -22px -20px 35px -29px rgba(0, 0, 0, 0.205),
//         -40px -40px 45px -29px rgba(0, 0, 0, 0.137);
//     }
//   }
// }
