#dash-redirects {
  .redirect-add-btn {
    padding: 1rem 2.5rem;
    border: none;
    background-color: rgb(86, 184, 123);
    color: white;
    font-size: 1.3rem;
    font-weight: 300;

    &:hover {
      text-decoration: none;
    }
  }
  .redirect-add-wrapper {
    max-height: 1000px;
    overflow: hidden;
    transition: 0.5s linear;
    &.hide {
      max-height: 0px;
      overflow: hidden;
      transition: 0.5s linear;
    }
  }
  .redirect-add-container {
  }
}
