#dash-brands {
  .list-group-item {
    cursor: pointer;
    border: none;
    //   border-bottom: 1px solid rgb(231, 231, 231);
    margin-bottom: 1px;

    background-color: rgb(249, 251, 251);
    background-image: linear-gradient(
      110deg,
      rgb(252, 252, 252) 50%,
      rgba(254, 255, 255, 0.548)
    );
    margin-bottom: 8px;
    border-left: 7px solid rgb(179, 186, 189);
    transition: 0.3s linear;
    &:hover {
      transform: scale(1.006);
      border-left: 7px solid rgb(157, 128, 170);
      box-shadow: 2px 2px 6px -3px rgba(0, 0, 0, 0.21);
      background-image: linear-gradient(
        110deg,
        rgb(250, 250, 250) 50%,
        rgb(249, 251, 252)
      );
    }
    &.new-lead-today {
      border-left: 7px solid rgb(156, 185, 199);
      transition: 0.3s linear;
    }
    &.new-lead {
      background-image: linear-gradient(
        110deg,
        rgb(255, 248, 245) 50%,
        rgb(249, 251, 252)
      );
      border-left: 7px solid rgb(216, 159, 121);
      transition: 0.3s linear;
    }
  }

  .list-item-key {
    display: flex;
    align-items: center;
    flex: 1;
    flex-basis: 280px;
    color: rgb(97, 97, 97);

    @media all and (max-width: 450px) {
      min-width: 250px;
      justify-content: space-between;
    }
  }

  .list-item-value {
    display: inline-block;
    max-width: 300px;
    font-size: 1.4rem;
    font-weight: 200;
    color: rgb(96, 136, 185);
    word-wrap: break-word;
    margin-left: 10px;

    &.text-sm {
      font-size: 1.25rem;
      font-weight: 400;
    }

    @media all and (max-width: 450px) {
      max-width: 180px;
    }
    @media all and (max-width: 1150px) {
      font-size: 1.2rem;
    }
  }

  .sub-li {
    flex-basis: 100%;
    max-height: 0;
    overflow: hidden;
    transition: 0.25s linear;

    &.expand {
      max-height: 1200px;
    }

    ul {
      // border-top: 1px solid rgb(223, 223, 223);
      margin-top: 16px;
      padding-left: 10px;
    }

    .sub-li-item {
      flex-basis: 32%;
      padding-bottom: 0.6rem;
      color: gray;
    }

    .list-item-value {
      line-height: 1.1;
      font-size: 1.1rem;
    }

    .sub-li__title {
      font-size: 1.6rem;
      flex-basis: 100%;
      color: rgb(167, 167, 167);
      font-weight: 200;
      padding-left: 18px;
      border-left: 5px solid rgb(185, 141, 207);
      line-height: 1.4;
      border-radius: 5px;
      box-shadow: -3px 1px 13px -6px rgba(0, 0, 0, 0.113);
      margin-bottom: 25px;
    }
  }

  .dropdown {
    cursor: pointer;
  }
  .btn {
    min-width: 150px;
    color: rgb(143, 143, 143);
    background-color: rgb(249, 251, 251) !important;
  }

  .delete-icon {
    display: flex !important;
    opacity: 1;
    color: rgb(105, 105, 105);
    .MuiSvgIcon-root:hover {
      color: rgb(216, 56, 56);
      cursor: pointer;
    }
  }

  @media all and (max-width: 450px) {
    .delete-icon {
      display: none !important;
      opacity: 0;
    }
    .delete-icon.clicked {
      display: flex !important;
      opacity: 1;
      color: rgb(105, 105, 105);
      .MuiSvgIcon-root:hover {
        color: rgb(216, 56, 56);
        cursor: pointer;
      }
    }
  }

  .time-from-container {
    cursor: pointer;
    border-radius: 10px;
    &:hover {
      .time-from {
        color: rgb(117, 103, 103);
        box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.07);
      }
    }
  }
  .time-from {
    box-shadow: 1px 1px 5px -3px rgba(0, 0, 0, 0);
    color: rgba(180, 180, 180, 0.637);
    transition: 0.2s linear;
  }
}
