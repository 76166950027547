@import url("https://fonts.googleapis.com/css?family=Kalam|Titillium+Web:300,400,600,700&display=swap");

$p-text_primary: #546e7a;

%theme_gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e8db2e+0,dd2a75+50,db2397+51,84bcf4+100 */
  background: -moz-linear-gradient(
    left,
    #e8db2e 0%,
    #dd2a75 45%,
    #db2397 55%,
    #84bcf4 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #e8db2e 0%,
    #dd2a75 45%,
    #db2397 55%,
    #84bcf4 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #e8db2e 0%,
    #dd2a75 45%,
    #db2397 55%,
    #84bcf4 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8db2e', endColorstr='#84bcf4',GradientType=1 ); /* IE6-9 */
}

.p-btn-gradient {
  @extend %theme_gradient;
  border: none;
  cursor: pointer;
  color: white;
  font-weight: 700;
}
