@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap");

#dashCard {
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  background-color: rgb(206, 83, 206);
  font-family: "Ubuntu", sans-serif;

  .dashcard-title {
    font-family: inherit;
    font-size: 1.2rem;
  }
  .dashcard-body {
    // font-family: inherit;
    font-size: 1.6rem;
    font-weight: 500;
  }
  .dashcard-meta {
    font-family: inherit;
    font-weight: 500;
  }

  &.dashcard-pink {
    background-image: linear-gradient(-30deg, #e94075, #e2a58d);
  }
  &.dashcard-purple {
    background-image: linear-gradient(
      -30deg,
      rgb(196, 70, 196),
      rgb(224, 122, 224)
    );
  }
  &.dashcard-pink-blue {
    background-image: linear-gradient(-35deg, #6e94ff, #eb7c9f 95%);
  }
  &.dashcard-green-blue {
    background-image: linear-gradient(-35deg, #70acec, #52d6c0 99%);
  }

  &::after {
    content: "";
    position: absolute;
    height: 105%;
    width: 40%;
    right: -15%;
    top: -15%;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
  }
  &::before {
    content: "";
    position: absolute;
    height: 60%;
    width: 20%;
    right: 5%;
    bottom: -20%;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
  }
}
