@import "../../../styles/utils.scss";

#offer {
  max-height: 2500px;
  overflow: hidden;
  transition: 0.25s linear;
  color: rgb(238, 238, 238) !important;
  // &.hide {
  //   max-height: 0px;
  //   overflow: hidden;
  //   animation: expand 0.4s linear forwards;
  // }
  .getoffer-wrapper {
    background: linear-gradient(170deg, #de3d70 -5%, #405698 62%);
    box-shadow: 12px 7px 15px -16px rgba(0, 0, 0, 0.37),
      22px 20px 40px -29px rgba(0, 0, 0, 0.705) !important;
  }
  .offer-display-title {
    font-size: 1.5rem;
    font-weight: 200 !important;
    border-bottom: 1px solid #ffffff24;
  }
  .offer-display-number {
    font-size: 2.1rem;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: "Titillium Web", sans-serif !important;
    text-shadow: 0 0 40px rgba(0, 0, 0, 0.233);
    width: fit-content;
    margin: 0 auto;
    padding: 6px 30px;
    // background-color: #4d6a8ee3;
  }
  .estimate-wrapper {
    width: fit-content;
    box-shadow: 0 -0.7rem 0.6rem rgba(0, 0, 0, 0.05) !important;
    border-radius: 4px;
    background: linear-gradient(#377d0f 30%, #377d0f33 80%, #60519100);
  }
  .estimate-title {
    font-size: 1.8rem;
    font-weight: 600;
    width: fit-content;
    padding: 5px 20px;
    border-radius: 4px;
    color: rgb(238, 176, 20);
  }
  .estimate-number {
    width: fit-content;
    font-size: 2.8rem !important;
    margin-top: -5px;
    font-weight: 600;
    font-family: "Titillium Web", sans-serif !important;
  }
  .code {
    background-color: rgb(161, 161, 161);
    padding: 1px 6px;
    border-radius: 3px;
    color: white;
  }
  .topic {
    background-color: #415484;
    font-size: 1.2rem;
    font-weight: 400;
    transform: translateY(30px);
    opacity: 0;
    animation: topicAnimation 0.5s linear forwards;
    @keyframes topicAnimation {
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  .alert-warning {
    animation: shake 0.2s linear forwards;

    @keyframes shake {
      20% {
        transform: translateX(-20px);
      }
      50% {
        transform: translateX(5px);
      }
      75% {
        transform: translateX(-10px);
      }
      100% {
        transform: translateX(0);
      }
    }
  }
}

@keyframes expand {
  100% {
    max-height: 1500px;
  }
}
