#banner {
  background-image: url(/imgs/banner.jpg);
  min-height: 500px;
  background-position: bottom center;
  background-color: #eae9e7;
  background-repeat: no-repeat;
  background-size: cover;
  .searchbar-wrapper {
    width: fit-content !important;
  }
  h2 {
    font-weight: 800 !important;
    line-height: 2.9rem !important;
    // letter-spacing: 0.1rem;
    font-size: 3.2rem;
    text-align: start;
    color: #546e7a;

    &:nth-of-type(1) {
      color: #5f7985;
    }
    &:nth-of-type(2) {
      font-size: 2.75rem;
    }
    &:nth-of-type(3) {
      color: #475d68;
    }
  }
}

@media all and (max-width: 576px) {
  #banner {
    background-position-x: -510px;
  }
}
@media all and (max-width: 768px) {
  #banner {
    h2 {
      font-size: 3rem !important;
      letter-spacing: initial;
      text-align: center;
    }
  }
}

@media all and (min-width: 1400px) {
  #banner {
    background-image: url(/imgs/banner-wide.jpg);
  }
}
@media all and (min-width: 1600px) {
  #banner {
    background-image: url(/imgs/banner-xwide.jpg);
  }
}

// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { ... }

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) { ... }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) { ... }

// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }
