@import "../../styles/utils.scss";

#aboutus {
  .page-title {
    color: $p-text-primary !important;
    width: fit-content;
    font-weight: 500;
  }
  .p-title {
    font-family: Arial, Helvetica, sans-serif !important;
    letter-spacing: 2px;
    color: $p-text-primary;
  }
  .row.aboutus-section {
    position: relative;
    &::before {
      content: "\201D";
      position: absolute;
      top: -20px;
      left: 0px;
      font-size: 300px;
      color: rgba(0, 0, 0, 0.03);
    }
    &::after {
      content: "\201D";
      position: absolute;
      bottom: -150px;
      right: 50%;
      font-size: 300px;
      color: rgba(0, 0, 0, 0.03);
    }
  }

  .why-ziigg {
    background-image: url(/imgs/cta1.jpg);
    position: relative;
    background-size: cover;
    background-position: center;
    z-index: -1;
    margin-top: 150px !important;
  }
  .why-ziigg-overlay {
    background-color: white;
    opacity: 0.93;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  .why-ziigg-title {
    color: #00acc1 !important;
    font-weight: 600;
    font-size: 3rem;
  }
  .why-ziigg-subtitle {
    color: rgba(0, 0, 0, 0.38) !important;
    font-weight: 200;
    letter-spacing: 0.2rem;
  }
  .why-ziigg-text {
    // color: rgba(0, 0, 0, 0.5) !important;
    font-weight: 200 !important;
    font-size: 1.3rem !important;
  }
}

@media all and (max-width: 760px) {
  .row.aboutus-section {
    background-color: #efeeea !important;
  }
}

// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { ... }

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) { ... }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) { ... }

// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }
