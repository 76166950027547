.dropDownTab {
  width: inherit;
  transition: 0.3s linear;
  &:hover {
    transform: scale(1.01);
  }
  .dropDownTab-main {
    height: auto;
    width: inherit;
    background-color: white;
    cursor: pointer;
  }
  .dropDownTab-title {
    font-size: 1.8rem;

    @media all and (max-width: 450px) {
      font-size: 1.2rem;
    }
  }
  .dropDownTab-dropDown {
    overflow: hidden;
    transition: 0.2s linear;
  }
  .dropDown-list {
  }
  .dropDown-item {
  }
}
